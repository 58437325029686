.card {
  background: var(--surface-card);
  padding: 1.25rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 70px;
  }
}
.p-panel {
  .p-panel-header {
    background-color: var(--gray-500) !important;
    .p-panel-title {
      font-weight: 600 !important;
      font-size: 16px;
    }
  }
}

.disable-custom {
  user-select: none;
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

@mixin minWidthStyleLabel($minWidth) {
  min-width: $minWidth !important;
  white-space: nowrap;
}

.min-w-label-1 {
  @include minWidthStyleLabel(5rem);
}

.min-w-label-2 {
  @include minWidthStyleLabel(7rem);
}

.min-w-label-3 {
  @include minWidthStyleLabel(10rem);
}

.w-fieldset{
  min-width: calc(100% - 4rem);
}
